import React, { useState } from 'react';
import useCollapse from 'react-collapsed';

const About = props => {
  const [isCollapseOpen, setCollapseOpen] = useState(false);
  const {getCollapseProps, getToggleProps} = useCollapse({isCollapseOpen});

  return (
    <div>
      <div className="app-listing-title">
        <h2 className="app-listing-title__heading heading--2">
          About Account Switcher
        </h2>
      </div>

      <div className={ isCollapseOpen ? "ui-expandable-content ui-expandable-content--expanded" : "ui-expandable-content"}>
        <div className="ui-expandable-content__inner">
          <div className="block">
            <p>
              With the Account Switcher installed in your Shopify store, you can allow agents and other intermediaries to assume a client’s identity - to shop on their behalf, or just to have a look at their account. With this app, agents no longer need to know their clients’ login credentials. As a bonus, you can keep track of who ordered what for whom.
            </p>
            <h4>This is how it works:</h4>
            <ol>
              <li>Create a regular customer account for an agent</li>
              <li>Assign agent status to the account</li>
              <li>Assign clients to an agent in your Shopify dashboard</li>
              <li>Now the agent can switch identities to have access to a client’s account</li>
            </ol>
            <div {...getCollapseProps()}>
              <h4>Who might benefit from Account Switcher?</h4>
              <p>
                Every Shopify merchant looking for a way to give intermediaries access to a client’s account, whether to place orders on behalf of the client or just to see what a client is seeing. This includes
                (but is certainly not limited to) B2B sales people ordering goods for their clients, or customer service employees logging in as a customer to see what they are seeing.
             </p>
            </div>
          </div>
        </div>
        <div className="ui-expandable-content__trigger-container">
          <button 
            className="marketing-button marketing-button--small marketing-button--secondary ui-expandable-content__expand-trigger"
            aria-hidden="true"
            {...getToggleProps({onClick: () => setCollapseOpen(true)})}
          >
            Show full description
          </button>
        </div>
        <div className="ui-expandable-content__collapse-trigger-container">
          <button 
            className="marketing-button--visual-link ui-expandable-content__collapse-trigger"
            aria-hidden="true"
            {...getToggleProps({onClick: () => setCollapseOpen(false)})}
          >
              Show less
          </button>
        </div>
      </div>
    </div>
  )
}

export default About;