import React from "react";

const Header = (props) => {
  return (
    <header>
      <div className="marketing-nav-wrapper">
        <nav className="marketing-nav marketing-nav__primary">
          <div className="marketing-nav__logo marketing-nav__logo__shopify">
            <span className="app-store-logo">
              <svg className="icon" role="img">
                <use xlinkHref="#v2-shopify_app_store_logo"></use>
              </svg>
            </span>
          </div>
          <ul className="marketing-nav__items marketing-nav__user display--expanded-nav">
            <li className="marketing-nav__item--nowrap">
              <span className="code-header-devby">developed by</span>
              <a
                href="https://code.nl"
                className="code-header-logo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg className="icon" role="img">
                  <use xlinkHref="#code-logo-header"></use>
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
