import React from "react";

const Pricing = (props) => {
  return (
    <div>
      <div className="grid grid--equal-height grid--vertically-centered">
        <hr className="hr grid__ruler" />
        <div className="grid__item">
          <div className="app-listing-title">
            <h2 className="app-listing-title__heading heading--2">
              Pricing
              <span className="app-listing-title__sub-heading">
                30-day free trial
              </span>
            </h2>
          </div>
        </div>

        <div className="grid__item grid__item--tablet-up-full">
          <div className="app-listing__pricing-plans">
            <div className="ui-card ui-card--large-padding pricing-plan-card pricing-plan-card--pricing-only">
              <div className="pricing-plan-card__title">
                <h5 className="pricing-plan-card__title-kicker">
                  Professional
                </h5>
                <h3 className="pricing-plan-card__title-header">$100/month</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="grid__item grid__item--tablet-up-full">
          <div className="app-listing__pricing-footnote">
            <p>
              * All charges are billed in USD. Recurring charges, including
              monthly or usage-based charges, are billed every 30 days.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
