import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import screenshot_manage from "../../images/accountswitcher_manage.png";
import screenshot_agent from "../../images/accountswitcher_agent.png";
import screenshot_settings from "../../images/accountswitcher_settings.png";

const MediaGallery = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const images = [screenshot_manage, screenshot_agent, screenshot_settings];

  const handleOpenImage = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div>
      <ul className="app-listing-media">
        <li className="app-listing-media__thumbnail">
          <div onClick={() => handleOpenImage(0)}>
            <img
              src={screenshot_manage}
              height="180"
              width="320"
              alt="Account Switcher: Manage agents"
            />
          </div>
        </li>
        <li className="app-listing-media__thumbnail">
          <div onClick={() => handleOpenImage(1)}>
            <img
              src={screenshot_agent}
              height="180"
              width="320"
              alt="Account Switcher: Agent details"
            />
          </div>
        </li>
        <li className="app-listing-media__thumbnail">
          <div onClick={() => handleOpenImage(1)}>
            <img
              src={screenshot_settings}
              height="180"
              width="320"
              alt="Account Switcher: Settings"
            />
          </div>
        </li>
      </ul>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + +1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default MediaGallery;
