import React from "react";

const AddApp = (props) => {
  return (
    <section
      id="addApp"
      className="section-block--light grid--bleed section-block--border-top js-track-inline-signup section-block"
    >
      <div className="page-width">
        <h2 className="marketing-form--inline__heading">Start now</h2>
        <p className="marketing-form--inline__subheading">
          Installing the Account Switcher takes only a few minutes.
        </p>
        <div className="grid-6 grid-push-1">
          <form
            className="marketing-form--inline"
            action="https://us-central1-shopify-account-switcher.cloudfunctions.net/shopifyApp/init"
            method="POST"
          >
            <div className="marketing-input-wrapper marketing-input-button-pair">
              <div className="marketing-input-button__field-wrapper">
                <label className="marketing-input-button-pair__input">
                  <input
                    className="marketing-input-button-pair__input marketing-input marketing-input--floating"
                    placeholder="your-shop.myshopify.com"
                    name="shop"
                  />
                </label>
                <button
                  className="marketing-button marketing-form__button marketing-input-button-pair__button"
                  type="submit"
                  name="button"
                >
                  Add app
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AddApp;
