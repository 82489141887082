import React from 'react';
import appIcon from '../../images/logo.svg';

const Hero = props => {

  return (
    <section className="section background-light color-ink section--tight ui-app-store-hero ui-app-store-hero--type-app-details">
      <div className="grid">
        <div className="grid__item">
          <div className="ui-app-store-hero__container">
            <div className="ui-app-store-hero__app-icon">
              <img src={appIcon} height="84" width="84" alt="Supercharged Store Emails" />
            </div>

            <div className="ui-app-store-hero__header">
              <h2 className="heading--2 ui-app-store-hero__header__app-name">
                Account Switcher
              </h2>
              <span className="heading-4 ui-app-store-hero__header__subscript">
                by <a href="https://www.code.nl" title="CODE" target="_blank" rel="noopener noreferrer">CODE</a> 
              </span>
            </div>

            <p className="heading--3 ui-app-store-hero__description">
            Do your clients regularly delegate their shopping to agents or other intermediaries? Or does your customer service need to see what a client sees in their account? Then you might be looking for a safe and easy way to give an intermediary access to a client’s account. The Account Switcher app allows you to do just that.
            </p>
            <div className="heading--4 ui-app-store-hero__footer">
              <div className="ui-app-pricing ui-app-pricing--format-detail">
                Requires a <a href="https://www.shopify.com/plus/enterprise-ecommerce?ref=cia" title="Shopify plus" target="_blank" rel="noopener noreferrer">Shopify Plus</a> subscription
              </div>
            </div>

            <div className="ui-app-store-hero__cta">
              <a className="marketing-button ui-app-store-hero__cta-button" href="#addApp">Add app</a>
            </div>

            <div className="ui-app-store-hero__media">
              <div className="ui-app-store-hero__media__video-container">
                <iframe className="ui-app-store-hero__media__video" src="https://www.youtube.com/embed/-rRcM0-PahI" frameBorder="0" allowFullScreen="allowfullscreen" title="Account Switcher video" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero;